.hamburger_backdrop 
{
    position:fixed;
   
    top: 0px;
    left:0px;
    z-index:10000;
}
  
  .hamburger_icon {
    position: fixed;
    background: whitesmoke;
    border: none;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    top:  0px;
    left: 0px;
    height: 25px;
    width: 20px;
    padding: 0px;
    margin: 10px;
    z-index:10000;
  }
  
 
  .menu_list {
    display:  box;
    position: fixed;
    flex-direction: column;
    background-color: white;
    top: 35px;
    left: 0;
    width: 40%;
    box-shadow: 0px 4px 8px rgba(0,0,0,0.1);
    z-index:10000;
  }
  
  .menu_list.open {
    display: block;
  }
  
  .menu_list ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu_list ul li {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .menu_list ul li:last-child {
    border-bottom: none;
  }
  
  .menu_list ul li a {
    text-decoration: none;
    color: black;
    display: block;
  }
  
  .hamburger_icon.open .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
  }
  
  .hamburger_icon.open .bar:nth-child(2) {
    opacity: 0;
  }
  
  .hamburger_icon.open .bar:nth-child(3) {
    transform: rotate(-45deg) translate(7px, -6px);
  }
  
.hamburger_text_white
{
  color:cornsilk;
  position: fixed;
  top:  10px;
  left: 40px;
 
}
  /*background-image: url('../pictures/Backdrop Image Portrait2.jpg');  */

.backdrop_portrait 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height: 4000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(128, 128, 128);
 /* z-index:3;*/
}


.grid_container_portrait
{
  display: grid;
  width: 100%;
  
  margin: 0 auto;
  border-radius: 0;
  padding: 0;

  grid-template-columns: (1fr);
  
  row-gap: 1rem;
  text-align: left;  

  align-items: start;
  justify-content: center;
}


.grid_container_portrait
{
  display: grid;
  width: 90%;
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;
  grid-template-columns:  1fr;
  row-gap: 1rem;
  column-gap: 0rem;
  text-align: left;  
  align-items: start;
  justify-content: center;
}

.grid_header_portrait
{
  padding: 0.5rem;
  margin: 0rem ;
  color: whitesmoke;
  background-color: #3e7cf8; 
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}


.grid_item_portrait 
{
  padding:1rem;
  margin: 0.5rem ;
  color: rgb(0, 0, 255);

  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}


.grid_image_portrait
{
  grid-column-start: 1;
  grid-column-end: 8;
  padding: 2rem;
  margin: 2rem ;
  background-color: rgb(255, 255, 255); 
  color: rgb(0, 0, 255);
  overflow: clip;
  border-radius: 10px;
  border: 1px solid #888;
  min-height: 20rem; 
  writing-mode:horizontal-tb;
  box-shadow: 3px 3px 5px 6px #444; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */

}


.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height:auto;
  align-items: start;
  justify-content: center;
  background-color: rgb(211,211,211);
 /* z-index:3;*/
}


.grid_container
{
  display: grid;
  width: 1000%;
  background-color: rgb(211,211,211);
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto auto ;
  row-gap: 1rem;
  column-gap: 0rem;
  text-align: left;  
  align-items: start;
  justify-content: center;
}



.grid_item 
{
  padding: 01rem;
  margin: 0rem ;
  color: rgb(0, 0, 255);

  text-align: left;
  word-wrap: break-word;
  overflow: clip;
}

/* Example Media Query 
@media (max-width: 768px) {
  .grid_item {
    font-size: clamp(0.7rem, 1.2vw, 1rem);
    padding: 0.5em;
  }
}*/


/*----------------------------------------------------------*/


.text
{
  color:rgb(0,0, 255);
  font-size: 14px;
}

.center
{
  text-align: center;
}
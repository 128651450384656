.backdrop_portrait 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height: 5000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(128,128,128);
 /* z-index:3;*/
}


.grid_container_portrait
{
  display: grid;
  width: 90%;
  background-color: #d2d808; 
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;
  grid-template-columns:  1fr;
  row-gap: 1rem;
  column-gap: 0rem;
  text-align: left;  
  align-items: start;
  justify-content: center;

}

.grid_header_portrait
{
  padding: 1rem;
  margin-bottom:  1rem ;
  color: whitesmoke;
  background-color: #3e7cf8; 
  text-align: center;
  word-wrap: break-word;
  overflow: clip;
}


.grid_image_portrait
{
  padding:0.5rem;
  margin: 1rem;
  margin-top: 1rem ;
  background-color: white; 
  color: rgb(0, 0, 255);
  overflow: clip;
  border-radius: 10px;
  border: 1px solid #888;
  min-height: 20rem; 
  writing-mode:horizontal-tb;
  box-shadow: 3px 3px 5px 6px #444; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */

}


.grid_item_portrait 
  {
    margin: 1rem;
    background-color: #f0f0f0; /* Light grey background for contrast */
    border-radius: 8px; /* Rounded corners for the grid items */
    padding: 20px; /* Padding inside grid items */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    min-height:content;
  }



/*----------------------------------------------------------*/

.backdrop 
{
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position:relative;
  min-height:1000px;
  align-items: start;
  justify-content: center;
  background-color: rgb(211, 211, 211);
 /* z-index:3;*/
}


/*----------------------------------------------------------*/

.button 
{
  height:       fit-content;
  right:        100px;
  border-width: 0;
  background-color: rgb(147, 250, 193);
  margin: 10px;
  border-radius: 20px;
  box-shadow: 2px 2px 3px 3px #3c3c3c; /* Horizontal offset, Vertical offset, Blur radius, Spread radius, Color */
  transition: 0.3s;
}

.button:disabled
{
  background-color: rgb(255,164,164);
}

.button:not(:disabled):active 
{
  box-shadow: 0 2px #666;
  transform: translateY(4px);
}


/*----------------------------------------------------------*/
/*
.grid_container
{
  display: grid;
  width: 80%;
  background-color: rgb(211,211,211);
  margin: 0 auto;
  border-radius: 1rem;
  padding: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows:auto auto;
  row-gap: 1rem;
  column-gap: 2rem;
  font-size: 1rem;
}
*/

 /*----------------------------------------------------------*/

.text_header
{
 padding-top: 30px ;
 color:rgb(0,0, 255) ;
 font-size: 14px;
 padding-left: 10px;
}

/*----------------------------------------------------------*/

.text
{
  color:rgb(142, 26, 76);
  font-size: 14px;
  text-align:  center;
}


/* CSS for the Grid Layout */
.grid_container 
{
    display: grid;
    grid-template-columns: 0.5fr;
    height: content;
    gap: 20px; /* Space between grid items */
    padding: 20px; /* Padding around the grid */
    align-items: start;
    justify-content: center;
  }
  
  .grid_item 
  {
    background-color: #f0f0f0; /* Light grey background for contrast */
    border-radius: 8px; /* Rounded corners for the grid items */
    padding: 20px; /* Padding inside grid items */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */
    min-height:content;
  }


  .grid_item_1
  {
    background-color: #f0f0f0; /* Light grey background for contrast */
    border-radius: 8px; /* Rounded corners for the grid items */
    padding: 0px; /* Padding inside grid items */
    box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for depth */

    font-size: 14px;
    text-align:  center;
  }
  /* Styling for the form within a grid item */
  .grid_item form {
    display: flex;
    flex-direction: column; /* Stack form elements vertically */
  }
  
  .grid_item form input,
  .grid_item form textarea,
  .grid_item form button {
    margin-bottom: 10px; /* Spacing between form elements */
    padding: 10px; /* Padding inside form elements */
    border: 1px solid #ccc; /* Border for form elements */
    border-radius: 4px; /* Rounded corners for form elements */
  }
  
  .grid_item form button {
    background-color: #007bff; /* Blue background for the submit button */
    color: white; /* White text for the submit button */
    cursor: pointer; /* Pointer cursor on hover */
  }
  
  .grid_item form button:hover {
    background-color: #0056b3; /* Darker blue on hover */
  }
  